import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import AuthSlice from "./Slices/AuthSlice";
import MasterSlice from "./Slices/MasterSlice";

const persistConfig = {
  key: "userAuth",
  storage: storageSession,
  whitelist: [AuthSlice.name, MasterSlice.name],
};

const reducers = combineReducers({
  [AuthSlice.name]: AuthSlice.reducer,
  [MasterSlice.name]: MasterSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export const persistor = persistStore(store);
