import { createSlice } from "@reduxjs/toolkit";

export interface IMasterState {
  master: any;
  loader: boolean;
  role_data: Record<string, any> | undefined;
  breadcrumb_history: string[];
}

const initialState: IMasterState = {
  master: {},
  loader: false,
  role_data: undefined,
  breadcrumb_history: [],
};

function subarrayFromLast(arr: any[], element: string[]) {
  let subArr: string[] = [];
  let index = -1;
  for (let i = arr.length - 1; i >= 0; i--) {
    if (element?.includes(arr[i]?.path)) {
      index = i + 1;
      break;
    }
  }
  if (index > -1) {
    subArr = arr.slice(index);
  } else {
    subArr = arr;
  }
  return subArr;
}
const resuceArray = (originalArray: any[]) => {
  // Find the index of the element that causes the match
  let matchIndex = -1;

  for (let i = 1; i < originalArray.length; i++) {
    const currentElement = originalArray[i]?.path;

    // Check if currentElement matches with any previous element
    for (let j = 0; j < i; j++) {
      const previousElement = originalArray[j]?.path;
      if (currentElement.startsWith(previousElement)) {
        matchIndex = j;
        break;
      }
    }

    if (matchIndex !== -1) {
      break; // Found the match index, no need to continue
    }
  }

  if (matchIndex !== -1) {
    // Remove all elements from the previous matching element index onwards
    const newArray = originalArray.slice(0, matchIndex + 1);
    return newArray;
  }
  return originalArray;
};

const MasterSlice = createSlice({
  name: "master",
  initialState,
  reducers: {
    getMasterData: (state, action) => ({
      ...state,
      master: {
        ...state.master,
        ...action.payload,
      },
    }),
    setLoader: (state, action) => ({
      ...state,
      loader: action.payload,
    }),
    clearMaster: (state) => ({
      ...state,
      master: {},
      loader: false,
    }),
    setRoleData: (state, action) => ({
      ...state,
      role_data: action.payload,
    }),
    setBreadcrumbRoute: (state, action) => {
      const history = [...state.breadcrumb_history, action.payload];
      const subarray = subarrayFromLast(history, [
        "/dashboard",
        "/bms/dashboard",
        "/mcu/dashboard",
        "/vcu/dashboard",
        "/driver/dashboard",
        "/fleet/dashboard",
        "/ev-owner/dashboard",
      ]);
      const nArr = resuceArray(subarray);

      return {
        ...state,
        breadcrumb_history: nArr,
      };
    },
    clearBreadcrumbRoute: (state) => ({
      ...state,
      breadcrumb_history: [],
    }),
  },
});

export const {
  getMasterData,
  setLoader,
  clearMaster,
  setRoleData,
  setBreadcrumbRoute,
  clearBreadcrumbRoute,
} = MasterSlice.actions;
export default MasterSlice;
